import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Helmet } from 'react-helmet';
import { useAuth } from '../../../../stores/hooks';

import { AppElement, AppFC } from '../../../../interfaces';
import { PasswordChangeDataProps } from './types';
import { ButtonCategory, Paths } from '../../../../constants';

import { FormInput } from '../../../Molecules';
import { Button } from '../../../Atoms';
import { ChangePasswordDataProps } from '../../../../services/httpRequest';
import { generateErrorAlert } from '../../helpers';

const validationSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.{6,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 6-32 characters, at least one letter, one number and one special character',
    ),
  newPassword1: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.{6,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 6-32 characters, at least one letter, one number and one special character',
    ),
  newPassword2: yup
    .string()
    .required('Please enter your password')
    .oneOf([yup.ref('newPassword1'), ''], 'Passwords do not match')
    .matches(
      /^(?=.{6,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 6-32 characters, at least one letter, one number and one special character',
    ),
});
export const PasswordChange: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const { passwordChange } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<PasswordChangeDataProps> = (data) => {
    setIsLoading(true);

    const newData: ChangePasswordDataProps = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword1,
    };

    passwordChange(newData).then(() => {
      setIsLoading(false);
      navigate(Paths.ProfileEdit);
    }).catch((reason) => {
      generateErrorAlert(reason, 'Password change failed!', setAlert);
      setIsLoading(false);
    });
  };

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Change Password</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 self-center justify-center gap-10 w-[400px]">
        {alert}
        <h4>Change Password</h4>
        <div className="flex flex-col items-center gap-8 w-full max-w-[400px]">
          <FormInput
            label="Old Password"
            name="oldPassword"
            type="password"
            control={control}
            placeholder="Enter old password"
            error={errors.oldPassword}
          />
          <FormInput
            label="New Password"
            name="newPassword1"
            type="password"
            control={control}
            placeholder="Enter new password"
            error={errors.newPassword1}
          />
          <FormInput
            label="Repeat New Password"
            name="newPassword2"
            type="password"
            control={control}
            placeholder="Enter new password"
            error={errors.newPassword2}
          />
        </div>
        <div className="flex gap-2">
          <Button
            className="w-[160px]"
            type="submit"
            category={ButtonCategory.Filled}
            isLoading={isLoading}
          >
            Save
          </Button>
          <Button
            className="w-[160px] bg-secondary hover:bg-secondary"
            category={ButtonCategory.Filled}
            onClick={() => {
              navigate(Paths.ProfileEdit);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};
