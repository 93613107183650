import { Helmet } from 'react-helmet';
import React from 'react';
import { AppElement, AppFC } from '../../../../interfaces';

import { OfferForm } from './OfferForm';

export const OfferCreateForm: AppFC = (): AppElement => (
  <>
    <Helmet>
      <title>Cryptolocally: Create Offer</title>
    </Helmet>
    <OfferForm />
  </>
);
