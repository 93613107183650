import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import moment from 'moment/moment';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';

import { Helmet } from 'react-helmet';
import { AuthToken } from '../../../../services';
import { createRoom } from '../../../../graphql/mutations';

import {
  ProfileContext, ViewContext, ViewModalActionTypes, WalletContext,
} from '../../../../stores';
import {
  useCurrency, useOffer, useTrade, useUser, useWallet,
} from '../../../../stores/hooks';

import {
  AppElement, AppFC, DisputeInterface, OfferInterface, TradeInterface, UserInterface, UserRatingInterface,
} from '../../../../interfaces';
import {
  ButtonCategory, GRAPHQL_CHAT_API, OfferStatusTypes, Paths, RatingGradeTypes, TradeStatusTypes, TradeUserTypes,
} from '../../../../constants';
import {
  getGradeType, getTradeStepInfo, numberWithCommas, tradeIsCompleted, TradeStepInfoInterface,
} from '../../../../utils';

import { Chat, FundingSteps, OfferTradeDetails } from '../../../Organisms';
import {
  Button, Card, Input, Loader, Stars,
} from '../../../Atoms';

import completedIcon from '../../../../assets/icons/completed.svg';

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_GRAPHQL_CHAT_API_URL as string,
      region: GRAPHQL_CHAT_API.REGION,
      defaultAuthMode: 'lambda',
    },
  },
});
export const Trade: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [disputeIsLoading, setDisputeIsLoading] = useState(false);
  const [offer, setOffer] = useState<OfferInterface>();
  const [trade, setTrade] = useState<TradeInterface>();
  const [rating, setRating] = useState(0);
  const [tradeIsRated, setTradeIsRated] = useState(false);
  const [ratingIsSucceed, setRatingIsSucceed] = useState(false);
  const [currentStepInfo, setCurrentStepInfo] = useState<TradeStepInfoInterface>();
  const [gasFee, setGasFee] = useState(0);
  const [offerUser, setOfferUser] = useState<UserInterface>();
  const [dispute, setDispute] = useState<DisputeInterface>();

  const { profileState: { id } } = useContext(ProfileContext);
  const { walletState: { wallets } } = useContext(WalletContext);
  const { updateViewModal } = useContext(ViewContext);

  const { tradeId } = useParams();
  const {
    getTradeById,
    fundTrade,
    markAsPaid,
    releaseEscrow,
    createDispute,
  } = useTrade();
  const { getCurrencyGasFee } = useCurrency();
  const { getOfferById } = useOffer();
  const { getUserById, getUserRatingById, rateUser } = useUser();
  const { getTradeDispute } = useTrade();
  const { getWalletBalance } = useWallet();

  const tokens = new AuthToken();
  const accessToken = tokens.getAccessToken();

  const navigate = useNavigate();
  const client = generateClient();

  const isBuyer = trade?.buyerUserId === id;
  const isSeller = trade?.sellerUserId === id;

  const currentTime = new Date().toISOString();
  const isExpired = moment(currentTime).isAfter(moment(trade?.endedAt));

  const wallet = wallets.find(({ cryptoCurrencyType }) => cryptoCurrencyType === offer?.offeredCurrency);
  const getTradeUserType = () => {
    switch (true) {
      case isBuyer:
        return TradeUserTypes.Buyer;
      case isSeller:
        return TradeUserTypes.Seller;
      default:
        return null;
    }
  };

  const getTrade = (itemId: string) => {
    getTradeById(itemId)
      .then((res) => {
        const { buyerUserId, sellerUserId } = res.data || {};
        if ((buyerUserId === id || sellerUserId === id)) {
          setTrade(res.data);
        } else {
          navigate(Paths.Offers);
        }
      }).catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (trade) {
      const getUser = () => {
        switch (true) {
          case isBuyer:
            return trade.sellerUserId;
          case isSeller:
            return trade.buyerUserId;
          default:
            return '';
        }
      };

      Promise.all([
        getOfferById(trade.offerId, { OfferStatus: OfferStatusTypes.Open }),
        getUserRatingById(getUser()),
      ]).then((res) => {
        setOffer(res[0]);

        if (res[1]) {
          const isRated = !!res[1].filter((item: UserRatingInterface) => item.raterId === id && item.tradeId === trade.id).length;
          setTradeIsRated(isRated);
        }

        getUserById(getUser()).then((user) => {
          const info = getTradeStepInfo(
            getTradeUserType() as TradeUserTypes,
            trade.tradeStatus,
            numberWithCommas(trade.offeredAmount, res[0].offeredCurrency, 6),
            user.userName,
          );
          setCurrentStepInfo(info);
          setOfferUser(user);
        }).catch(() => {
          setOfferUser({} as UserInterface);
        });
      }).catch(() => {
        setOffer({} as OfferInterface);
      });

      const timer = setInterval(() => {
        getTrade(trade.id);
      }, 10000);

      // temporary code
      if (tradeIsCompleted(trade.tradeStatus)) {
        clearInterval(timer);
      }

      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [trade]);

  useEffect(() => {
    if (tradeId) {
      getTrade(tradeId);
    }
  }, [tradeId]);

  useEffect(() => {
    if (offer) {
      getCurrencyGasFee(offer.offeredCurrency).then((res) => {
        setGasFee(res?.gasFee);
      });
    }
  }, [offer]);

  useEffect(() => {
    if (wallet) {
      getWalletBalance(wallet.id)
        .then().catch((error) => {
          console.log('error', error);
        });
    }
  }, [wallet?.id]);

  useEffect(() => {
    if (trade?.tradeStatus === TradeStatusTypes.Completed) {
      getTradeDispute(trade.id)
        .then((res) => {
          setDispute(res);
        }).catch((error) => {
          console.log('error', error);
        });
    }
  }, [trade?.tradeStatus]);

  const handleFundEscrow = () => {
    if (tradeId) {
      setIsLoading(true);
      fundTrade(tradeId).then(() => {
        getTrade(tradeId);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Trade fund failed!',
          },
        });
      });
    }
  };

  const handleMarkAsPaid = () => {
    if (tradeId) {
      setIsLoading(true);
      markAsPaid(tradeId).then(() => {
        getTrade(tradeId);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Pay failed!',
          },
        });
      });
    }
  };

  const handleReleaseFunds = () => {
    if (tradeId) {
      setIsLoading(true);
      releaseEscrow(tradeId).then(() => {
        getTrade(tradeId);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Trade fund failed!',
          },
        });
      });
    }
  };

  const handleOpenDispute = () => {
    if (tradeId) {
      setDisputeIsLoading(true);
      createDispute(tradeId).then(() => {
        Promise.all([
          client.graphql({
            authToken: accessToken,
            query: createRoom,
            variables: {
              input: {
                name: `${tradeId}+${trade?.buyerUserId}`,
                userId: trade?.buyerUserId as string,
              },
            },
          }),
          client.graphql({
            authToken: accessToken,
            query: createRoom,
            variables: {
              input: {
                name: `${tradeId}+${trade?.sellerUserId}`,
                userId: trade?.sellerUserId as string,
              },
            },
          })]).then(() => {
          getTrade(tradeId);
          setDisputeIsLoading(false);
        }).catch((reason) => {
          setIsLoading(false);
          updateViewModal({
            type: ViewModalActionTypes.FailModal,
            payload: {
              title: 'Dispute chat boxes create failed!',
              description: reason.response?.data?.detail || '',
            },
          });
        });
      }).catch((reason) => {
        setDisputeIsLoading(false);
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Open dispute failed!',
            description: reason.response?.data?.detail || '',
          },
        });
      });
    }
  };

  const getStepAction = () => {
    if (isBuyer) {
      switch (trade?.tradeStatus) {
        case TradeStatusTypes.Funded:
          return {
            label: 'Mark as paid',
            action: handleMarkAsPaid,
          };
        case TradeStatusTypes.Paid:
          return {
            label: '',
            action: () => {},
            openDispute: handleOpenDispute,

          };
        default:
          return {
            label: '',
            action: () => {},
          };
      }
    }
    if (isSeller) {
      switch (trade?.tradeStatus) {
        case TradeStatusTypes.Open:
          return {
            label: 'Fund escrow',
            action: handleFundEscrow,
          };
        case TradeStatusTypes.Paid:
          return {
            label: 'Release',
            action: handleReleaseFunds,
            openDispute: handleOpenDispute,
          };
        default:
          return {
            label: '',
            action: () => {},
          };
      }
    }

    return {
      label: '',
      action: () => {},
    };
  };

  const handleRatingClick = () => {
    if (trade) {
      setIsLoading(true);
      const ratingData = {
        tradeId: trade.id,
        ratingGradeType: getGradeType(rating) as RatingGradeTypes,
      };
      rateUser(ratingData)
        .then(() => {
          setRatingIsSucceed(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Trade</title>
      </Helmet>
      {!(trade && offer && currentStepInfo) ? <Loader className="absolute self-center justify-self-center top-0 h-screen" />
        : (
          <div className="flex flex-col gap-12 pt-5 w-full h-full max-w-[1120px] self-center">
            {!tradeIsCompleted(trade.tradeStatus) ? (
              <FundingSteps
                tradeStatus={trade.tradeStatus}
                currentStepInfo={currentStepInfo}
                startTime={trade.createdAt}
                endTime={trade.endedAt}
                stepCount={4}
                type={getTradeUserType()}
                stepAction={getStepAction()}
                isLoading={isLoading}
                disputeIsLoading={disputeIsLoading}
              />
            )
              : (
                <div className="h-full flex flex-col items-center justify-center gap-24">
                  <div className="flex flex-col items-center gap-10">
                    <img src={completedIcon} alt="" />
                    <div className="flex flex-col gap-3 items-center text-center">
                      <h3>Trade is completed</h3>
                      {trade.tradeStatus === TradeStatusTypes.Refunded
                        ? (
                          <div>
                            <span>
                              {numberWithCommas(trade.offeredAmount + gasFee + trade.escrowFee, trade?.offeredCurrency, 8)}
                              {' '}
                            </span>
                            {' '}
                            was refunded to buyer.
                          </div>
                        ) : (
                          <div>
                            You
                            {' '}
                            {isSeller ? 'sent' : 'received'}
                            {' '}
                            {isSeller ? (
                              <span>
                                {numberWithCommas(trade.offeredAmount + gasFee + trade.escrowFee, trade?.offeredCurrency, 8)}
                                {' '}
                              </span>
                            )
                              : (
                                <span>
                                  {numberWithCommas(trade.offeredAmount, trade?.offeredCurrency, 8)}
                                  {' '}
                                </span>
                              )}
                            {' '}
                            for
                            {' '}
                            <span>
                              {numberWithCommas(trade.demandedAmount, trade.demandedCurrency, 5)}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  {!tradeIsRated && trade.tradeStatus === TradeStatusTypes.Completed && !dispute && (
                  <Card className="p-7 gap-8 max-w-[400px] w-full">
                    <h4 className="text-[20px] leading-none">{ratingIsSucceed ? 'Thank you' : 'Rate transaction'}</h4>
                    <Stars rating={rating} setRating={setRating} gap={3} isClickable={!ratingIsSucceed} />
                    {ratingIsSucceed
                      ? <h4 className="font-normal mb-5">Your rating is submitted</h4>
                      : (
                        <Button
                          className="p-0 w-[100px] h-10 font-semibold text-[14px]"
                          category={ButtonCategory.Filled}
                          onClick={handleRatingClick}
                          isLoading={isLoading}
                        >
                          Submit
                        </Button>
                      )}
                  </Card>
                  )}
                  <NavLink to="/offers">
                    <Button category={ButtonCategory.Default}>
                      Browse Offers
                    </Button>
                  </NavLink>
                </div>
              )}
            <div className="flex justify-between gap-[12%] max-lg:gap-[7%] max-md:flex-col max-md:gap-10 max-md:items-center">
              {isBuyer && !tradeIsCompleted(trade.tradeStatus) && (
              <div className="flex flex-col flex-1 gap-6 max-w-[500px] w-full">
                <OfferTradeDetails
                  offer={offer}
                  escrowFee={trade.escrowFee}
                  amount={trade.offeredAmount}
                  isBuyer={isBuyer}
                  isSeller={isSeller}
                />
              </div>
              )}
              {isSeller && (
              <div className="flex flex-1 flex-col gap-8">
                { !isExpired && (
                <>
                  <Card>
                    <div className="flex justify-between py-[18px] px-6 max-w-[500px] w-full">
                      <div>
                        <span className="text-default">Platform wallet balance</span>
                        <h5>
                          {numberWithCommas(wallet?.balance, wallet?.cryptoCurrencyType, 3)}
                        </h5>
                      </div>
                    </div>
                  </Card>
                  <div className="flex flex-col justify-center items-center gap-8">
                    <h5>Please deposit to the below address:</h5>
                    <div className="rounded-lg bg-white p-2 h-[140px] w-[140px]">
                      <QRCodeSVG
                        className="h-full w-full"
                        value={wallet!.address}
                        size={300}
                      />
                    </div>
                    <h3>{numberWithCommas(trade.offeredAmount, wallet?.cryptoCurrencyType, 4)}</h3>
                  </div>
                  <Input value={wallet?.address} copyable />
                </>
                )}
                {!tradeIsCompleted(trade.tradeStatus) && (
                <OfferTradeDetails
                  offer={offer}
                  escrowFee={trade.escrowFee}
                  amount={trade.offeredAmount}
                  isBuyer={isBuyer}
                  isSeller={isSeller}
                />
                )}
              </div>
              )}
              {!tradeIsCompleted(trade.tradeStatus) && <Chat user={trade.tradeStatus === TradeStatusTypes.Disputed ? { userName: 'Admin' } as UserInterface : offerUser} trade={trade} className="flex-1 max-w-[500px]" />}
            </div>
          </div>
        )}
    </>
  );
};
