import { Helmet } from 'react-helmet';
import React from 'react';
import { AppElement, AppFC } from '../../../../interfaces';
import { AuthToken } from '../../../../services';

import signUpImage from '../../../../assets/images/guides/1. Sign up.png';
import signUpCompleteImage from '../../../../assets/images/guides/2. Complete account.png';
import newOfferImage from '../../../../assets/images/guides/3. New Offer.png';
import offerTypeImage from '../../../../assets/images/guides/4. Selecting Offer type.png';
import selectCryptocurrencyImage from '../../../../assets/images/guides/5. Select Cryptocurrency.png';
import paymentMethodImage from '../../../../assets/images/guides/6. Select Payment Methods.gif';
import countryImage from '../../../../assets/images/guides/7. Select Country.gif';
import tradeImage from '../../../../assets/images/guides/8. Trade Details.png';
import publishDraftImage from '../../../../assets/images/guides/9. Publish or Draft.png';
import myOffersImage from '../../../../assets/images/guides/10. My Offers.png';

export const GuideCreateOffer: AppFC = (): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = !!authToken.getAccessToken() && !!authToken.getRefreshToken();

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Guides - Creating Offers</title>
      </Helmet>
      <div className="flex flex-col flex-1 h-auto w-screen bg-white text-black [&_h3]:leading-[2rem] [&_p]:text-[1.25rem] [&_p]:leading-[2.25rem] [&_p]:font-normal [&_a]:text-[1.25rem] [&_a]:leading-[2.25rem] [&_a]:font-normal [&_a]:text-tertiary">
        <div className={`flex flex-col gap-10 pt-12 pb-16 bg-[#f2f5f9] ${!isAuth ? 'px-[7%]' : 'px-[3%]'} [&_img]:rounded-lg [&_img]:max-w-5xl [&_img]:w-full"`}>
          <div className="">
            <h2 className="font-semibold mb-6">How to create an offer</h2>
            <p>
              CryptoLocally recognizes the importance of a strong community. Having such a tight-knit community and customizable offers created the rich marketplace that we have today. You may want to create an offer that is tailored to your needs. In this case, this guide will show you how to create an offer, step-by-step.
            </p>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-8">1. Sign Up</h3>
            <p>
              You must have an account for CryptoLocally to make an offer. Don’t worry, signing up on CryptoLocally is quick and simple.
            </p>
            <p>
              Input your email address and choose a CryptoLocally username and password. After verifying your email and entering some more basic information, you are ready to start trading on CryptoLocally.
            </p>
            <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] mt-6 mb-12">
              <img src={signUpImage} alt="Sign up" />
              <span>Image 1. Creating an account on Cryptolocally</span>
            </div>
            <p>
              After verifying your email, you will be asked for some more basic information, like your name, country of residence, and phone number. Then, please enter the code we sent to your phone number. All verification helps build a basis of trust for new users.
            </p>
            <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
              <img src={signUpCompleteImage} alt="Sign up complete" />
              <span>
                Image 2. Entering more basic information for sign up
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-8">2. Create New Offer</h3>
            <p>
              Once you have created your account and are logged in, click on “New offer” (see image 3).
            </p>
            <div className="flex flex-col gap-4 items-center [&>span]:text-[1rem] my-6">
              <img src={newOfferImage} alt="New offer" />
              <span>
                Image 3. Start making an offer
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-8">3. Select your offer type</h3>
            <p>
              Select your offer type depending on your goal (see image 4). If you are trying to create an offer to sell cryptocurrency, then click on “Sell.” If you are trying to buy cryptocurrency, click on “Buy.”
            </p>
            <div className="flex flex-col gap-4 items-center gap-4 [&>span]:text-[1rem] my-6">
              <img src={offerTypeImage} alt="Offer type" />
              <span>
                Image 4. Selecting offer type
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-8">4. Pick your Cryptocurrency</h3>
            <p>
              Select the cryptocurrency you want to sell; the crypto or fiat currency you want to receive as payment; and the country you are trading in. Once you have done all that, click on “Next step” (see image 5). In this example, let&aposs assume you want to sell BTC for USD.
            </p>
            <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
              <img src={selectCryptocurrencyImage} alt="Cryptocurrency" />
              <span>
                Image 5. Select what you want to trade
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-4">5. Select your Payment Method</h3>
            <p>
              The next thing is to select the payment method(s) you want to use to receive your crypto or fiat currency. After you have selected the payment methods, click on “Next step” (see image 6).
            </p>
            <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] mt-6 mb-12">
              <img src={paymentMethodImage} alt="Payment method" />
              <span>
                Image 6. Select payment methods you want to be paid in
              </span>
            </div>
            <p>
              You will then be asked what city you are trading from. Fill in your city and click “confirm” (see image 7). We ask this to inform the user you are trading with about potential time zone differences.
            </p>
            <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
              <img src={countryImage} alt="Country" />
              <span>
                Image 7. Enter city of residence
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-4">6. Trade Details</h3>
            <p>
              You have great control over the pricing for your trade offer (see image 8). You can customize the following:
            </p>
            <ul className="list-disc px-10 py-6 [&>li]:text-[1.25rem]">
              <li>
                Which market rate you want to use in determining the price of your crypto
              </li>
              <li>
                The rate of margin (e.g. how much of a premium or discount that you are offering)
              </li>
              <li>
                The minimum and maximum cryptocurrency volume you are willing to trade
              </li>
              <li>
                A note to inform the trader about any other terms you have
              </li>
            </ul>
            <p>
              Once you are done click on “Next step.”
            </p>
            <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
              <img src={tradeImage} alt="Trade" />
              <span>
                Image 8. Trade details
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-4">7. Publish Offer</h3>
            <p>
              Review your offer and make sure there are no mistakes. Finally, click on “Publish” (see image 9).
            </p>
            <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] my-6">
              <img src={publishDraftImage} alt="Publish&Draft" />
              <span>
                Image 9. Review and publish offer
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-4">8. Live Offer</h3>
            <p>
              Congratulations! Your offer is now officially live on CryptoLocally! You can review, edit or delete your offers anytime by clicking My offers (see image 10).
            </p>
            <div className="flex flex-col items-center gap-4 [&>span]:text-[1rem] mt-6 mb-12">
              <img src={myOffersImage} alt="Offers" />
              <span>
                Image 10. Find my offers
              </span>
            </div>
            <img src="" alt="" />
            <p>
              If for some reason you have trouble with creating an offer, please contact support:
              {' '}
              <a href="mailto:support@cryptolocally.com" target="_blank" rel="noreferrer">support@cryptolocally.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
