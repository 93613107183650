import React, { useState } from 'react';
import {
  NavLink,
  useNavigate,
} from 'react-router-dom';
import {
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Helmet } from 'react-helmet';
import { useAuth } from '../../../../stores/hooks';

import { AppElement, AppFC } from '../../../../interfaces';
import { SignUpFormProps } from './types';
import { AuthPaths, ButtonCategory, Paths } from '../../../../constants';

import { Button } from '../../../Atoms';
import { FormInput } from '../../../Molecules';
import { checkString } from '../../../../utils';
import { generateErrorAlert } from '../../helpers';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is invalid')
    .required('Email is required'),
  username: yup
    .string()
    .required('Please enter your user name'),
  password: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.{6,32}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      ' ',
    ),
});

const passwordChecks = [
  {
    regex: '^.{6,32}$',
    message: '• 6-32 characters',
  },
  {
    regex: '.*[0-9].*',
    message: '• At least one number',
  },
  {
    regex: '(?=.*[a-z])(?=.*[A-Z])',
    message: '• At least one uppercase and one lowercase letter',
  },
  {
    regex: '[!"#$%&\'()*+,\\-./:;<=>?@[\\\\\\]^_`{|}~]',
    message: '• At least one special character',
  },
];

export const SignUp: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const navigate = useNavigate();
  const { signUp, sendEmail } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      username: '',
      password: '',
    },
  });

  const currentPassword = useWatch({
    control,
    name: 'password', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: '', // default value before the render
  });

  const onSubmit: SubmitHandler<SignUpFormProps> = (data) => {
    setIsLoading(true);
    const {
      email = '',
      username = '',
      password = '',
    } = data;
    signUp({
      email,
      userName: username,
      password,
    })
      .then(() => {
        sendEmail({ email })
          .then(() => {
            navigate(
              AuthPaths.VerifyEmailConfirm,
              {
                state: {
                  alertMessage: 'Verification email sent!',
                  emailAddress: email,
                },
              },
            );
          }).catch((reason) => {
            setIsLoading(false);
            generateErrorAlert(reason, 'Send Verification Email failed!', setAlert);
          });
      })
      .catch((reason) => {
        setIsLoading(false);
        generateErrorAlert(reason, 'Sign up failed!', setAlert);
      });
  };

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Sign up</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 py-36">
        {alert}
        <h4>Create a free account</h4>
        <div className="flex flex-col gap-6 justify-center w-full">
          <FormInput
            label="Email"
            name="email"
            control={control}
            placeholder="example@gmail.com"
            error={errors.email}
          />
          <FormInput
            label="Username"
            name="username"
            control={control}
            placeholder="Enter username"
            error={errors.username}
          />
          <FormInput
            label="Password"
            name="password"
            type="password"
            control={control}
            placeholder="Enter password"
            error={errors.password}
          />
          <div className="text-disabled">
            {passwordChecks.map(({ regex, message }, index) => (
              !checkString(regex, currentPassword) ? (
                <h6 key={`password-check-${index}`}>
                  {message}
                </h6>
              ) : null
            ))}
          </div>
        </div>
        <Button
          className="w-[200px]"
          type="submit"
          category={ButtonCategory.Filled}
          isLoading={isLoading}
        >
          Sign up
        </Button>
        <span className="flex items-center gap-2 text-disabled">
          Already have an account?
          <NavLink to={AuthPaths.SignIn} className="underline text-tertiary">
            Log in
          </NavLink>
        </span>
        <span className="flex flex-wrap gap-1.5 [&>a]:underline [&>a]:text-tertiary text-disabled">
          By registering you agree to the
          <NavLink to={Paths.TermsOfUse}>Terms of Use</NavLink>
          and
          <NavLink to={Paths.PrivacyPolicy}>Privacy Policy.</NavLink>
        </span>
      </form>
    </>
  );
};
