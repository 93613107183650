import {
  useEffect, createRef, useState, useRef,
} from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { SwitchProps } from './types';

export const Switch: AppFC<SwitchProps> = ({
  items,
  seperated = false,
  inOneLine = true,
  direction = 'horizontal',
  setValue,
  value: activeItem,
  width,
  height,
  itemClass,
  activeItemClass,
}): AppElement => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isFocused, setIsFocused] = useState(false);

  const sectionRef = useRef<HTMLDivElement>(null);
  const buttonRefs = Array.from({ length: items.length }, () => createRef<HTMLButtonElement>());

  const handleKeyDown = (event: KeyboardEvent) => {
    if (isFocused) {
      if (direction === 'vertical') {
        event.preventDefault();
        if (event.key === 'ArrowDown') {
          setSelectedIndex((prevIndex: number) => (prevIndex + 1) % buttonRefs.length);
        } else if (event.key === 'ArrowUp') {
          setSelectedIndex((prevIndex: number) => (prevIndex - 1 + buttonRefs.length) % buttonRefs.length);
        }
      } else if (event.key === 'ArrowRight') {
        setSelectedIndex((prevIndex: number) => (prevIndex + 1) % buttonRefs.length);
      } else if (event.key === 'ArrowLeft') {
        setSelectedIndex((prevIndex: number) => (prevIndex - 1 + buttonRefs.length) % buttonRefs.length);
      }
    }
  };

  useEffect(() => {
    const initialSelectedIndex = items.findIndex(({ value }) => activeItem === value);
    if (initialSelectedIndex !== -1) {
      setSelectedIndex(initialSelectedIndex);
    }
  }, []);

  useEffect(() => {
    if (isFocused) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isFocused]);

  useEffect(() => {
    console.log('selectedIndex', selectedIndex);
    setValue(items[selectedIndex].value);
    buttonRefs[selectedIndex].current?.focus();
  }, [selectedIndex]);

  if (seperated) {
    return (
      <div
        ref={sectionRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        tabIndex={0} // allows div to be focusable
        className={`w-fit h-fit flex items-center gap-2 ${!inOneLine ? 'flex-wrap' : ''} outline-none focus:outline-none`}
      >
        {items?.map(({ value, label }, index) => (
          <span
            key={`${value}-${index}`}
            ref={buttonRefs[index]}
            className={`
            ${itemClass || ''}
            ${activeItem === value ? ` ${activeItemClass || 'text-primary bg-primary'} border border-blue` : 'text-grey bg-input hover:bg-inputHover border border-default'} 
            transition-all duration-100 ease-in
            rounded-[0.25rem]
            flex items-center justify-center
            ${width || 'w-20'}
            ${height || 'h-10'}
            cursor-pointer
            `}
            onClick={() => {
              setValue(value);
              setSelectedIndex(index);
            }}
          >
            {label}
          </span>
        ))}
      </div>
    );
  }

  if (direction === 'vertical') {
    return (
      <div
        ref={sectionRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        tabIndex={0} // allows div to be focusable
        className="outline-none focus:outline-none w-fit h-fit rounded-[0.25rem] overflow-hidden flex flex-col items-center cursor-pointer"
      >
        {items?.map(({ value, label }, index) => (
          <span
            key={`${value}-${index}`}
            ref={buttonRefs[index]}
            className={`
              ${activeItem === value ? ` ${activeItemClass || 'text-primary bg-primary'} border border-blue` : 'text-grey bg-input hover:bg-inputHover border border-default'} 
              transition-all duration-200 ease-in 
              flex items-center justify-center
              ${width || 'w-20'}
              ${height || 'h-10'}
            `}
            onClick={() => {
              setValue(value);
              setSelectedIndex(index);
            }}
          >
            {label}
          </span>
        ))}
      </div>
    );
  }

  return (
    <div
      ref={sectionRef}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      tabIndex={0} // allows div to be focusable
      className="outline-none focus:outline-none w-fit h-fit rounded-xl overflow-hidden border border-blue flex items-center cursor-pointer"
    >
      {items?.map(({ value, label }, index) => (
        <span
          key={`${value}-${index}`}
          ref={buttonRefs[index]}
          className={`
            ${activeItem === value ? 'text-primary bg-primary' : 'text-tertiary bg-card hover:bg-inputHover '}
            ${items.length > 2 && index > 0 && index < items.length - 1 ? 'border-l border-r border-blue' : ''}
            transition-all duration-200 ease-in 
            flex items-center justify-center
            ${width || 'w-20'}
            ${height || 'h-10'}
            font-semibold
          `}
          onClick={() => {
            setValue(value);
            setSelectedIndex(index);
          }}
        >
          {label}
        </span>
      ))}
    </div>
  );
};
