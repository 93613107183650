import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AppElement, AppFC } from '../../../../interfaces';
import { PasswordResetProps } from './types';
import { AlertCategory, AuthPaths, ButtonCategory } from '../../../../constants';
import {
  Button,
  Alert,
} from '../../../Atoms';
import { useAuth } from '../../../../stores/hooks';
import { FormInput } from '../../../Molecules';
import { checkString } from '../../../../utils';

const validationSchema = yup.object().shape({
  newPassword1: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.{6,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      ' ',
    ),
  newPassword2: yup
    .string()
    .required('Please enter your password')
    .oneOf([yup.ref('newPassword1'), ''], 'Passwords must match'),
});
export const PasswordReset: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const [searchParams] = useSearchParams();
  const tokenOrigin = searchParams.get('token') || '';
  const token = tokenOrigin.replaceAll(' ', '+');
  const email = searchParams.get('email');

  const navigate = useNavigate();

  const passwordChecks = [
    {
      regex: '^.{6,32}$',
      message: '• 6-32 characters',
    },
    {
      regex: '.*[0-9].*',
      message: '• At least one number',
    },
    {
      regex: '(?=.*[a-z])(?=.*[A-Z])',
      message: '• At least one uppercase and one lowercase letter',
    },
    {
      regex: '[!"#$%&\'()*+,\\-./:;<=>?@[\\\\\\]^_`{|}~]',
      message: '• At least one special character',
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      newPassword1: '',
      newPassword2: '',
    },
  });

  const { passwordResetConfirm } = useAuth();

  console.log('emailAddress', email);

  const currentPassword = useWatch({
    control,
    name: 'newPassword1', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: '', // default value before the render
  });

  const onSubmit: SubmitHandler<PasswordResetProps> = (data) => {
    if (email && token) {
      setIsLoading(true);

      passwordResetConfirm({ password: data.newPassword1, email, token })
        .then((res) => {
          setIsLoading(false);
          setAlert(
            <Alert
              category={AlertCategory.Success}
              title={res.data.detail}
            />,
          );
          setTimeout(() => {
            setAlert(null);
          }, 5000);
          navigate(AuthPaths.SignIn, { state: { alertMessage: res.data.detail, emailAddress: email || '' } });
        })
        .catch((reason) => {
          setIsLoading(false);
          console.log('reason.response', reason.response);
          setAlert(
            <Alert
              category={AlertCategory.Error}
              title="Password reset failed!"
              description={reason.response.data.detail || reason.response.data.token[0]!}
            />,
          );
          setTimeout(() => {
            setAlert(null);
          }, 5000);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Reset Password</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 h-full justify-center">
        {alert}
        <h4>Set new password</h4>
        <div className="flex flex-col gap-6 justify-center w-full">
          <FormInput
            label="Password"
            name="newPassword1"
            type="password"
            control={control}
            placeholder="Enter password"
            error={errors.newPassword1}
          />
          <FormInput
            label="Repeat password"
            name="newPassword2"
            type="password"
            control={control}
            placeholder="Enter password"
            error={errors.newPassword2}
          />
          <div className="text-disabled">
            {passwordChecks.map(({ regex, message }, index) => (
              !checkString(regex, currentPassword) ? (
                <h6 key={`password-check-${index}`}>
                  {message}
                </h6>
              ) : null
            ))}
          </div>
        </div>
        <Button
          className="w-[200px]"
          type="submit"
          category={ButtonCategory.Filled}
          isLoading={isLoading}
        >
          Reset Password
        </Button>
      </form>
    </>
  );
};
