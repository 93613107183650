import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { ProfileContext, WalletContext } from '../../../../stores';

import { useAuth, useWallet } from '../../../../stores/hooks';

import { AuthToken } from '../../../../services';
import { AppElement, AppFC } from '../../../../interfaces';
import { MenuProps } from './types';

import {
  ButtonCategory, currencies, currencyIcons, HeaderCategory, Paths,
} from '../../../../constants';
import { Button, Loader, Avatar } from '../../../Atoms';

import offersIcon from '../../../../assets/icons/offers.svg';
import walletsIcon from '../../../../assets/icons/wallets.svg';
import tradesIcon from '../../../../assets/icons/tradeHistory.svg';
import { numberWithCommas } from '../../../../utils';
import { WalletInterface } from '../../../../stores/contexts';

export const ProfileMenu: AppFC<MenuProps> = ({
  category = HeaderCategory.App, toggleMenu, menuIsOpen,
}): AppElement => {
  const authToken: AuthToken = new AuthToken();
  const isAuth = authToken.getAccessToken() && authToken.getRefreshToken();
  const profileMenuRef = useRef<HTMLDivElement | null>(null);

  const { profileState: { userName, profileImage } } = useContext(ProfileContext);
  const { walletState: { wallets } } = useContext(WalletContext);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { signOut } = useAuth();
  const { getWalletBalance } = useWallet();

  const getCurrencyOptions = () => {
    const balances = currencies.map((currency) => {
      const existingWallet = wallets.find(({ cryptoCurrencyType }) => cryptoCurrencyType === currency);
      return { [currency]: existingWallet?.balance || 0 };
    });

    return balances.map((balance) => {
      const currency = Object.keys(balance)[0];
      const currencyBalance = Object.values(balance)[0];
      return (
        <div className="flex h-full w-full py-2 justify-between items-center gap-2 text-white [&_img]:w-4 border-b border-listItem/40">
          <div className="h-full flex items-center gap-1">
            <img src={currencyIcons[currency]} alt="" />
            <span className="leading-normal text-[10px]">{currency}</span>
          </div>

          {(currencyBalance !== undefined) && <span className="leading-none text-[10px]">{numberWithCommas(Number(currencyBalance), '', Number(currencyBalance) ? 5 : 2)}</span>}
        </div>
      );
    });
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (profileMenuRef.current && !profileMenuRef.current.contains(e.target as Node)) {
      if (menuIsOpen) {
        toggleMenu(false);
      }
    }
  };
  const handleLogoutClick = () => {
    setIsLoading(true);
    signOut().then(() => {
      navigate(Paths.Home);
      setIsLoading(false);
      toggleMenu(!menuIsOpen);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (wallets.length) {
      const promises = wallets.map((wallet: WalletInterface) => getWalletBalance(wallet.id));
      Promise.all(promises).then(() => {
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
    }
  }, [wallets.length]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuIsOpen]);

  return (
    <div ref={profileMenuRef} className={`absolute bg-default p-4 top-20 ${isAuth && category === HeaderCategory.App ? 'right-[3%] max-lg:right-[calc(3%_+_46px)]' : 'right-[7%] max-lg:right-[calc(7%_+_46px)]'} flex flex-col items-end gap-4 min-w-[270px] rounded-2xl z-40`}>
      {isAuth && category === HeaderCategory.App && (
      <>
        <div className="flex items-center gap-4 h-10 self-start">
          <Button
            className={`w-10 h-10 p-0 rounded-[50%] overflow-hidden ${profileImage ? 'bg-black' : ''}`}
            category={profileImage ? ButtonCategory.Default : ButtonCategory.Filled}
          >
            {!profileImage ? userName?.substring(0, 1).toUpperCase()
              : <Avatar src={profileImage} size={10} />}
          </Button>
          <div className="h-full flex flex-col justify-between">
            <h5 className="leading-[100%]">{userName}</h5>
            <NavLink to={Paths.ProfileEdit} className="leading-[100%] text-tertiary underline">
              View Profile
            </NavLink>
          </div>
        </div>
        <div className="w-full">
          {getCurrencyOptions()}
        </div>
        <div className="flex flex-col gap-3 w-full [&>a]:h-9 [&>a]:flex [&>a]:gap-2 [&>a]:items-center [&>a]:rounded [&>a]:px-1">
          <NavLink to={Paths.Wallets} className="hover:bg-white/10">
            <img src={walletsIcon} alt="wallets" />
            Wallets
          </NavLink>
          <NavLink to={Paths.MyOffers} className="hover:bg-white/10">
            <img src={offersIcon} alt="wallets" />
            My offers
          </NavLink>
          <NavLink to={Paths.Trades} className="hover:bg-white/10">
            <img src={tradesIcon} alt="wallets" />
            Trade history
          </NavLink>
        </div>

        <button
          className="h-10 w-full text-error font-normal self-center"
          onClick={handleLogoutClick}
        >
          {isLoading ? <Loader /> : 'Logout'}
        </button>
      </>
      )}
    </div>
  );
};
