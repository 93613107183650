import React, { useContext, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useOffer } from '../../../../stores/hooks';
import {
  OfferContext, ProfileContext, ViewContext, ViewModalActionTypes,
} from '../../../../stores/contexts';

import { AppElement, AppFC } from '../../../../interfaces';
import { offerOptions, OfferStatusTypes, OfferTypes } from '../../../../constants';

import { DraftOfferCard, MyOfferBox } from '../../../Molecules';
import { Loader, Switch } from '../../../Atoms';

export const MyOffers: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [offerIsDeleting, setOfferIsDeleting] = useState(false);
  const [currentOfferType, setCurrentOfferType] = useState(OfferTypes.All);

  const { profileState: { id: userId } } = useContext(ProfileContext);
  const { offerState: { publishedOffers, draftOffers } } = useContext(OfferContext);
  const { updateViewModal } = useContext(ViewContext);

  const { getOffers, deleteOffer } = useOffer();

  useEffect(() => {
    if (!offerIsDeleting) {
      setIsLoading(true);
      const paramsForPublished = currentOfferType === OfferTypes.All ? { OfferStatus: OfferStatusTypes.Open, UserId: userId } : { OfferType: currentOfferType, OfferStatus: OfferStatusTypes.Open, UserId: userId };
      const paramsForDraft = currentOfferType === OfferTypes.All ? { OfferStatus: OfferStatusTypes.Draft, UserId: userId } : {
        OfferType: currentOfferType,
        OfferStatus: OfferStatusTypes.Draft,
        UserId: userId,
      };
      Promise.all([
        getOffers(paramsForPublished),
        getOffers(paramsForDraft),
      ])
        .then(() => {
          setIsLoading(false);
        })
        .catch((reason) => {
          console.log('reason', reason);
          setIsLoading(false);
        });
    }
  }, [currentOfferType, offerIsDeleting]);

  const handleDeleteClick = (id: string) => {
    setOfferIsDeleting(true);
    deleteOffer(id).then(() => {
      updateViewModal({
        type: ViewModalActionTypes.SuccessModal,
        payload: {
          title: 'Success',
          description: 'Offer deleted successfully.',
        },
      });
      setOfferIsDeleting(false);
    }).catch(() => {
      setOfferIsDeleting(false);
    });
  };

  return (
    <>
      <Helmet>
        <title>Cryptolocally: My Offers</title>
      </Helmet>
      <div className="flex flex-col self-center gap-10 w-full py-20 px-[3%] md:px-[3%] xl:px-[8%]">
        <div>
          <h3>My offers</h3>
        </div>

        <Switch
          name="offerType"
          items={offerOptions}
          itemClass="font-semibold"
          value={currentOfferType}
          setValue={(value) => { setCurrentOfferType(value as OfferTypes); }}
          height="h-12"
        />

        {isLoading ? <Loader className="self-center justify-self-center" /> : (
          <div className="flex flex-col gap-12">
            <div>
              {publishedOffers.map((offer) => (
                <MyOfferBox offer={offer} onDeleteClick={handleDeleteClick} />))}
            </div>
            {draftOffers.length > 0 && (
              <div>
                <h5>Drafts</h5>
                <div className="grid grid-cols-3 max-2xl:grid-cols-2 max-md:grid-cols-1 gap-4 mt-6">
                  {draftOffers.map((offer) => (
                    <DraftOfferCard offer={offer} onDeleteClick={handleDeleteClick} />))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
