import React, {
  useContext, useState,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Helmet } from 'react-helmet';
import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../../stores';
import { useProfile } from '../../../../stores/hooks';

import { AppElement, AppFC } from '../../../../interfaces';
import { ProfileDataProps } from './types';
import { ButtonCategory, Paths } from '../../../../constants';

import { FormInput, FormSelect } from '../../../Molecules';
import { Button } from '../../../Atoms';
import { getCountries } from '../../../../utils';
import { ProfileImage } from '../../../Molecules/ProfileImage';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is invalid'),
  userName: yup
    .string().required('Please enter your user name'),
  firstName: yup
    .string(),
  lastName: yup
    .string(),
  country: yup
    .string().required(),
});
export const ProfileEdit: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    profileState: {
      email,
      userName,
      fullName,
      country,
      loginCount,
    },
  } = useContext(ProfileContext);

  const { updateViewModal } = useContext(ViewContext);

  const { updateProfile } = useProfile();

  const {
    control,
    handleSubmit,
    formState: { errors, defaultValues },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email,
      userName,
      firstName: fullName?.firstName || '',
      lastName: fullName?.lastName || '',
      country: country || '',
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<ProfileDataProps> = (data) => {
    setIsLoading(true);
    const {
      firstName = '', lastName = '',
    } = data;
    const newData = {
      email: data.email!,
      userName: data.userName!,
      fullName: { firstName, lastName },
      country: data.country!,
      loginCount,
    };

    updateProfile(newData).then(() => {
      setIsLoading(false);
      updateViewModal({
        type: ViewModalActionTypes.SuccessModal,
        payload: {
          description: 'Profile data saved!',
        },
      });
    }).catch((reason) => {
      setIsLoading(false);
      updateViewModal({
        type: ViewModalActionTypes.FailModal,
        payload: {
          title: 'Profile update failed!',
          description: reason.detail,
        },
      });
    });
  };

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Profile</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col self-center gap-8 w-[calc(20%+220px)] max-w-[400px] py-36">
        <h4>Profile</h4>
        <ProfileImage />
        <div className="flex flex-col items-center gap-6 w-full max-w-[400px]">
          <FormInput
            label="Email"
            name="email"
            control={control}
            placeholder="Enter email"
            error={errors.email}
            blocked
          />
          <FormInput
            label="Username"
            name="userName"
            control={control}
            placeholder="Enter username"
            error={errors.userName}
          />
          <FormInput
            label="First Name"
            name="firstName"
            control={control}
            placeholder="Enter first name"
            error={errors.firstName}
          />
          <FormInput
            label="Last Name"
            name="lastName"
            control={control}
            placeholder="Enter last name"
            error={errors.lastName}
          />
          <FormSelect
            label="Country"
            name="country"
            options={getCountries()}
            defaultValue={defaultValues?.country}
            control={control}
            placeholder="Select country"
            onChange={(value) => { setValue('country', value as string); }}
            error={errors.country}
          />
        </div>
        <NavLink to={Paths.ChangePassword} className="underline text-tertiary">
          Change Password
        </NavLink>
        <div className="flex gap-2 [&>button]:w-[160px]">
          <Button
            type="submit"
            category={ButtonCategory.Filled}
            isLoading={isLoading}
          >
            Save
          </Button>
          <Button
            category={ButtonCategory.Default}
            onClick={() => { navigate(Paths.Offers); }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};
