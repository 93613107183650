import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { ProfileContext } from '../../../../stores';
import { useTrade } from '../../../../stores/hooks';

import { AppElement, AppFC, TradeInterface } from '../../../../interfaces';
import { tradeIsCompleted } from '../../../../utils';

import { Loader } from '../../../Atoms';
import { TradeBox } from '../../../Organisms';

export const Trades: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [trades, setTrades] = useState<TradeInterface[]>();

  const { profileState: { id: userId } } = useContext(ProfileContext);

  const { getTrades } = useTrade();

  const currentTrades = trades?.filter((trade) => (trade.buyerUserId === userId || trade.sellerUserId === userId));

  useEffect(() => {
    getTrades().then((response) => {
      setTrades(response);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      // error handler
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Trades</title>
      </Helmet>
      {isLoading ? <Loader className="self-center justify-self-center h-[calc(100%_-_96px)]" />
        : (
          <div className="flex flex-col self-center max-sm:items-center gap-9 w-full py-20">
            <h3>
              Active Trades
            </h3>
            <div className="flex flex-col">
              {currentTrades?.filter(({ tradeStatus }) => !tradeIsCompleted(tradeStatus)).map((trade) => (
                <NavLink to={`/trades/${trade.id}`}><TradeBox trade={trade} /></NavLink>))}
            </div>
            <h3 className="mt-20">
              Finished Trades
            </h3>
            <div className="flex flex-col">
              {currentTrades?.filter(({ tradeStatus }) => tradeIsCompleted(tradeStatus)).map((trade) => (
                <NavLink to={`/trades/${trade.id}`}><TradeBox trade={trade} /></NavLink>))}
            </div>
          </div>
        )}
    </>

  );
};
