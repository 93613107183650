import React, { useContext } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { OfferContext } from '../../../../stores/contexts';

import { AppElement, AppFC } from '../../../../interfaces';
import { Paths } from '../../../../constants';

import { OfferForm } from './OfferForm';

export const OfferEditForm: AppFC = (): AppElement => {
  const { offerId } = useParams();
  const { offerState: { draftOffers } } = useContext(OfferContext);

  const offer = draftOffers.find(({ id }) => offerId === id);

  return offer ? (
    <>
      <Helmet>
        <title>Cryptolocally: Edit Offer</title>
      </Helmet>
      <OfferForm offer={offer} />
    </>
  ) : <Navigate to={Paths.Offers} />;
};
