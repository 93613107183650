import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { ProfileContext } from '../../../../stores';
import { useAuth } from '../../../../stores/hooks';

import { AppElement, AppFC } from '../../../../interfaces';
import {
  AlertCategory, AuthPaths,
} from '../../../../constants';

import { Alert, Button } from '../../../Atoms';
import { generateErrorAlert } from '../../helpers';

export const EmailVerification: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const { profileState: { email: userEmail } } = useContext(ProfileContext);
  const [searchParams] = useSearchParams();
  const tokenOrigin = searchParams.get('token') || '';
  const pathEmail = searchParams.get('email');
  const token = tokenOrigin.replaceAll(' ', '+');
  const navigate = useNavigate();
  const location = useLocation();
  const { alertMessage, emailAddress } = location.state || {};
  const email = userEmail || emailAddress;

  const { emailVerification, sendEmail } = useAuth();

  useEffect(() => {
    if (alertMessage) {
      setAlert(
        <Alert
          category={AlertCategory.Success}
          title={alertMessage}
        />,
      );
      setTimeout(() => {
        setAlert(null);
      }, 7000);
    } else {
      setAlert(null);
    }
  }, []);

  useEffect(() => {
    if (pathEmail && token) {
      emailVerification({ email: pathEmail, token }).then(() => {
        navigate(AuthPaths.SignIn, { state: { alertMessage: 'Your Email Successfully Verified!' } });
      }).catch((reason) => {
        generateErrorAlert(reason, 'Email verification failed!', setAlert);
        setTimeout(() => {
          setAlert(null);
        }, 7000);
      });
    }
  }, [pathEmail, token]);

  const handleResend = () => {
    setIsLoading(true);
    if (email) {
      sendEmail({ email })
        .then(() => {
          setIsLoading(false);
          setAlert(
            <Alert
              category={AlertCategory.Success}
              title="New verification email sent!"
            />,
          );
          setTimeout(() => {
            setAlert(null);
          }, 7000);
        })
        .catch((reason) => {
          setIsLoading(false);
          generateErrorAlert(reason, 'Email resend failed!', setAlert);
          setTimeout(() => {
            setAlert(null);
          }, 7000);
        });
    } else {
      setIsLoading(false);
    }
  };

  if (!(pathEmail && token)) {
    return (
      <>
        <Helmet>
          <title>Cryptolocally: Email verification</title>
        </Helmet>
        <div className="relative h-full flex flex-col justify-center">
          {alert}
          <h4>Verification</h4>
          <p className="text-[15px] mt-4">{`Thanks for registering an account. We’ve just sent an email to ${email || 'example@gmail.com'} to verify your address.`}</p>
          <div className="flex items-center gap-2 text-disabled text-[14px] mt-8">
            <span>Didn&apos;t get an email?</span>
            <Button
              className="h-auto p-0 font-normal text-tertiary underline"
              isLoading={isLoading}
              onClick={handleResend}
            >
              Resend
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Cryptolocally: Email verification</title>
      </Helmet>
      {alert}
    </>
  );
};
